<template>
  <div class="about">
    <h2>About me</h2>
    <img class="meImg" src="../assets/Projekte/Ich/BewerbigPBklein.png">
    <p class="text">I am Marc Häusler, 24 years old and come from Zug. Following my apprenticeship as a software developer, I wanted to pursue my creativity. 
      This led me to begin my studies in <a target="_blank" href="https://www.hslu.ch/en/lucerne-school-of-information-technology/degree-programs/bachelor/digital-ideation/">Digital Ideation</a> which is where I found my passion for UX. 
      In July 2021, I successfully completed my Bachelor in Computer Science with focus on UX.</p>

      <p class="text">
          My biggest strength is my communicative personality which helps me engage with users during interviews and testings. It also helps me 
          to share my ideas with stakeholders and the team working on a project. 
        </p>    

    <h2>Hobbies</h2>
    <video controls style="max-width: 100%;">
        <source src="../assets/Projekte/Ich/ski.mp4" type="video/mp4">                              
          Your browser does not support the video tag.
      </video>
    <p class="text">During winter time you will usually find me on the slopes or in the fun park.</p>

    <img class="processImage" src="../assets/Projekte/Ich/boulder.jpg">
    <p class="text">Recently I started bouldering. I particularly enjoy the mix of strategic thinking and physical endurance it requires to get better.</p>

    <iframe class="ytube" width="100%" height="400px" src="https://www.youtube.com/embed/0BD8u4wRKsM" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p class="text">I play the game "Super Smash Brother's Melee" (released on the Gamecube in 2001) for which I've been travelling abroad for tournaments and made many friends around the world. </p>
  
  </div>
</template>

<style scoped>
  .meImg{
    width: 50%;  
  }
  .ytube{
    width: 100%;
    height: 200px;
  }

  @media (min-width: 585px){
    .meImg{
      max-width: 300px;
    }

    .ytube{
      height: 400px;
    }
  }
</style>