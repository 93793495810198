<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">DESIGN PRINCIPLE ANALYSIS</h1>
    <img class="previewImage" src="../assets/Projekte/PrincipleAnalysis/preview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>What</h3>
      <p class="text">
        In a previous course I created the website "digitalplanetenweg" together with Seraphine Thürler and Levin Witjes. The website is 
        a digital extension to the physical "Planetenweg" between Emmenbrücke and Buchrain. There you can find informations about the different 
        planets in our solar system. As we didn't have much time back then, we weren't able to test the website at all. Therefore I wanted to 
        atleast go over the website and think about improvements in the design and functionality. 
      </p>

      <h3>How</h3>
      <p class="text">
        For a mentoring project I was able to do exactly that. First I did some research on different design principles for mobile websites and 
        design in general. After I searched websites with a similar purpose to ours in order to apply the principles on them. This allowed me to get 
        a feeling for the principles and also were a great comparison. Finally I applied the principles on "digitalplanetenweg" and designed 
        alternatives or improvements for it.  
      </p>

      <img class="processImage" src="../assets/Projekte/PrincipleAnalysis/3_analysis2.png">

      <h3>Result</h3>
      <p class="text">
        There were many different pages or specific elements which I could analyse and then found some possible improvements for them. 
        This also helped me to gather knowledge about web and mobile design that I can use for future projects.
      </p>

      <img class="processImage" src="../assets/Projekte/PrincipleAnalysis/4_alternativeDesigns.png">

      <a class="downloadLink" href="../assets/Projekte/PrincipleAnalysis/AnalysisDesignPrinciples_MarcHäusler.pdf" download="" target="_blank">
          <div class="downloadFlex">
            <div class="iconDiv"><span class="material-icons-outlined md-36">file_download</span></div>
            <div class="textDiv">Full report</div>
          </div>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Principles',
  mounted() { 
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
  h2{
    color: #70A9A1;
  }
</style>