<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">ÄRZTEHAUS VISCOSI</h1>
    <img class="previewImage" src="../assets/Projekte/Ärztehaus/preview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>My Responsibilities</h3>
      <p class="responsibilities">
        Research, Interviewing, Prototyping, Usability Testing
      </p>

      <h3>Project brief</h3>
      <p class="text">
        Clickable prototype for online appointment scheduling at an imaginary medical center for customers lacking time, courage oder motivation to make a call. 
      </p>

      <h3>The Problem</h3>
      <p class="text">
        Scheduling an appointment can be difficult for many different reasons. Mostly it's a lack of motivation to make a call or we don't have time during our 
        daily life to make a call during the opening hours. 
      </p>

      <h3>The Goal</h3>
      <p class="text">
        Our goal was to build a clickable prototype for scheduling an appointment at a medical center without any struggles. Therefore we build an interface for scheduling 
        the appointment online. It was important to get enough data for the medical center when scheduling online - but not too much to overwhelm the users.
      </p>

      
    </div>

    <h2>The Process</h2>
    
    <div class="process">
      <h3>Research</h3>
      <p class="text">
        In the first phase we conducted interviews with co students and medical centres. We wanted to find out why people don't like to call in order to make appointments. With medical centres we wanted to find out what information they need and how they can manage their scheduling.
      </p>
      
      <img class="processImage" src="../assets/Projekte/Ärztehaus/1ResearchFindings.png">


      <h3>Brainstorming</h3>
      <p class="text">
        With the information we gathered from the interviews we then brainstormed ideas for what the scheduling tool should feature. We then decided to create an online tool which could be featured on a website.
      </p>

      <h3>Prototyping</h3>
      <p class="text">
        For this project we used the tool Axure to make a fully clickable and functioning prototype. It features a form for entering the data for the appointment, a calendar overview to see the available dates and a livechat.
      </p>

      <img class="processImage" src="../assets/Projekte/Ärztehaus/3Prototyp.png">

      <h3>Usability Testing</h3>
      <p class="text">
        We tested the prototype with co students and let them schedule a full appointment. We observed how they entered the data and then asked questions about the understandability afterwards. 
      </p>

      <h4>Key Findings</h4>
      <ul class="keyfindings">
        <li>Livechat went unnoticed or wasn't needed</li>
        <li>Setting different dates and times together is confusing</li>
        <li>Order of input fields could be reconsidered based on importance</li>
        <li>Comment field went unnoticed because of placement</li>
      </ul>

      <h3>Redesign</h3>
      <p class="text">
        Based on these key findings we changed the different elements in order to improve visibility and understandability.
      </p>

      <h4>Date and time before</h4>
      <img class="redesignImage" src="../assets/Projekte/Ärztehaus/zeit_vorher.png">
      <h4>Date and time after</h4>
      <img class="redesignImage" src="../assets/Projekte/Ärztehaus/zeit_nachher.png">

      <h2>Links</h2>
      <a target="_blank" href="https://drive.google.com/drive/folders/1pO8TPMHBBsyH143IrmDXLaWrf-cyxNtA">Download Prototype</a>
      
      <h3>Collaboration with</h3>
      <p class="collaboration">
        Lea Kuslev, <br> Marlon Stadler
      </p>
    </div>
    

  </div>
  <button class="backToBtn btmBtn projectBackBtn" v-on:click="scrollToTop">
      back to top
  </button>
</template>

<script>

export default {
  name: 'Aerzte',
  mounted() { 
    window.scrollTo(0, 0)
  },
  methods: {
    scrollToTop: function (){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  h2{
    color: #70A9A1;
  }

  .redesignImage{
    max-width: 80%;
  }
</style>