<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">UX TEST ENVIRONMENT</h1>
    <img class="previewImage" src="../assets/Projekte/Suchfelder/preview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>What</h3>
      <p class="text">
        My lecturer Angie Born had the question whether search fields perform better or worse depending on their design. 
        For a mentoring project I chose to try and find an answer to this question. 
      </p>

      <h3>How</h3>
      <p class="text">
        At first I started with a literature research which I then ended quickly again as I had an idea. Since I'm an ex software developer 
        I have the skills to build my own websites. With that in mind I pitched my own UX test environment idea to Angie Born. What I created is a 
        website which has two template sites (Mozilla firefox settings page and HSLU's landing page) and chooses a design option for the 
        search field randomly. When the user is ready and clicks start, a time measure is started in the background and stops as soon as the user clicks 
        in the search field. Sending out the website to friends and the school emails I gathered around 200 measurements which I then evaluated statistically.
      </p>

      

      <h3>Result</h3>
      <img class="processImage" src="../assets/Projekte/Suchfelder/3_UXStudioResultatePlot.png">
      <p class="text">
        In this analysis with the used test settings and context, the design option of the searchfield did not result in a statistically significant improvement.
      </p>

      <a class="downloadLink" href="../assets/Projekte/Suchfelder/UXRapport_MarcHäusler.pdf" download="" target="_blank">
          <div class="downloadFlex">
            <div class="iconDiv"><span class="material-icons-outlined md-36">file_download</span></div>
            <div class="textDiv">Full report</div>
          </div>
      </a>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Searchfields',
  mounted() { 
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
  h2{
    color: #70A9A1;
  }
</style>