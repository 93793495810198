<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">MeSo - MEGA SOZIAL APP</h1>
    <img class="previewImage" src="../assets/Projekte/Bachelorarbeit/preview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>My Responsibilities</h3>
      <p class="responsibilities">
        Research, Interview planning, Interviewing, Wireframing, Prototyping, Usability Testing
      </p>

      <h3>Project brief</h3>
      <p class="text">
        Together with Janine Richener I wanted to use my Bachelor's project as a chance to help people living with 
        multiple sclerosis (MS) in their everyday life. MS affects each person differently - therefore their needs 
        during their life vary alot.
      </p>

      <h3>The Problem</h3>
      <p class="text">
        Talking with the Schweizerische MS Gesellschaft and people with MS we found out that people with MS struggle 
        to keep in touch with other people. Another problem is that they can't handle certain daily activities on their own 
        and need to rely on help from others.
      </p>

      <h3>The Goal</h3>
      <p class="text">
        Our goal is to build a platform for people with MS as well as volunteers in order to connect them and push social exchange. 
        Another key goal is the possibility to ask for or provide help for others in their daily activities.  
      </p>

      <img class="processImage" src="../assets/Projekte/Bachelorarbeit/preview_praktisch.png">
    </div>

    <h2>The Process</h2>
    
    <div class="process">
      <h3>Research - Interviews</h3>
      <p class="text">
        In order to understand the problems that people with MS face in their daily life and which needs our platform could fulfil 
        I organized and conducted interviews with them. The questions were based on social exchange and support during daily life. 
      </p>
      <h4>Key Findings</h4>
      <ul class="keyfindings">
        <li>Connections to other people with MS are created at MS relevant events or places</li>
        <li>Young people affected by MS still can and want to do activities with others</li>
        <li>In most cases help is provided by family or friends</li>
        <li>Many affected don't need help and want to provide help themselves</li>
        <li>Accepting help from strangers is a learning process</li>
    </ul>


      <h3>Wireframing</h3>
      <p class="text">
        After creating personas and defining key features of the app with them, I started with the first wireframe. 
        The purpose of this wireframe was to test where the features should be placed in the app and whether people understand the navigation. 
      </p>

      <img class="processImage" src="../assets/Projekte/Bachelorarbeit/LoFi2.png">

      <p class="text">
        The LoFi wireframe was tested with 4 people affected by MS and volunteers and then changed according to the feedback. 
        After the changes it was then tested with another 4 people with MS and volunteers. 
      </p>

      <h4>Key Findings</h4>
      <ul class="keyfindings">
        <li>Setting up a call for help or a call for a social event was not distuingishable</li>
        <li>The used icons were not clear enough</li>
        <li>Filters for the lists of social events or help pages needed</li>
        <li>Saving a help call as a template not clear</li>
      </ul>

      <h3>HiFi Prototyping</h3>
      <p class="text">
        While I was conducting testings with the wireframe, Janine prepared a styling guide with all the colors, sizes and icons that we will use. 
        With this I was able to transform the wireframe into a HiFi prototype screen by screen. 
      </p>

      <img class="processImage" src="../assets/Projekte/Bachelorarbeit/HiFi2.png">

      <h3>Usability Testing</h3>
      <p class="text">
        The prototype was also tested in two iterations - 5 people with MS and 4 volunteers for each iteration. After that I changed the prototype 
        according to their feedback again and that is also the final state of this bachelor project.
      </p>

      <h2>Links</h2>
      <a target="_blank" href="https://www.figma.com/proto/L8RjdWKzjB9FS8QrRTrA2l/Prototyp?page-id=185%3A15&node-id=207%3A979&viewport=964%2C456%2C0.07065695524215698&scaling=scale-down">Figma Prototype</a><br>
      <a target="_blank" href="https://www.multiplesklerose.ch/de/">Schweizerische MS Gesellschaft</a><br>
      <a target="_blank" href="https://hub.hslu.ch/design-kunst/wwwerkschau/werke/janine-richener/?tag=Digital">Project on HSLU WWWERKSCHAU</a>

      <h2>Next steps</h2>
      <p class="text">
        This project will be pitched to the Schweizerische MS Gesellschaft and other relevant organisations. We want to find a team 
        who will realise this prototype as all people with MS showed us their excitement and their need for a platform like this. 
        <br>
        This work got graded a 5.2
      </p>
    </div>

    <h3>Collaboration with</h3>
      <p class="collaboration">
        Janine Richener (Design)
      </p>
  </div>

  <button class="backToBtn btmBtn projectBackBtn" v-on:click="scrollToTop">
      back to top
  </button>
</template>

<script>

export default {
  name: 'Meso',
  mounted() { 
    window.scrollTo(0, 0)
  },
  methods: {
    scrollToTop: function (){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  h2{
    color: #40798C;
  }
  
</style>