<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">DIGITAL INCLUSION SWITZERLAND</h1>
    <img class="previewImage" src="../assets/Projekte/Bachelorarbeit/theoriePreview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>What</h3>
      <p class="text">
        For the theoretical project we had to write a scientific work relating to the digital world. 
        As my practical project is about MS, I wanted to write about something related to MS. 
        Therefore I chose to analyse different Swiss websites used by people with MS on their accessibility.
      </p>

      <h3>How</h3>
      <p class="text">
        First I asked 5 people with MS what websites they use regularly because of MS. With that I was able to 
        choose the categories: Health insurance, physical therapy and other insurance services. Within these categories I chose 3 websites each. 
        These were then analysed based on the Accessibility Checklist 2.0 which is based on the WCAG 2.0.
      </p>

      <h3>Result</h3>
      <p class="text">
        The analysis shows that private websites have many possible problems and obstacles for people with MS, even though they 
        should be aware that people with disabilities belong to their main customers. <br>
        This work got graded a 5.9

      </p>

      <a class="downloadLink" href="../assets/Projekte/Bachelorarbeit/Häusler_Marc_BA_DI_FS21_Digitale_Inklusion_der_Schweiz.pdf" download="" target="_blank">
          <div class="downloadFlex">
            <div class="iconDiv"><span class="material-icons-outlined md-36">file_download</span></div>
            <div class="textDiv">Full report</div>
          </div>
      </a>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Theorie',
  mounted() { 
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
  h2{
    color: #40798C;
  }
</style>