<template>
  <div class="projectpage">
    <h1 class="projectBigTitle">INFO-WERKSTÄTTEN</h1>
    <img class="previewImage" src="../assets/Projekte/Infowerkstätte/preview.png">
    <h2>Overview</h2>
    
    <div class="overview">
      <h3>My Responsibilities</h3>
      <p class="responsibilities">
        Research, Interviewing, Prototyping, Usability Testing, Programming
      </p>

      <h3>Project brief</h3>
      <p class="text">
        For our very first UX project in my study we were able to choose our own project. Thanks to a fellow student we got a real project which was required. 
        The Musikkonservatorium in Zurich holds a yearly event called "Info-Werkstätten". At this event visitors can try out instruments which are spread out in different 
        rooms and stories of the building. We started as a UX group project and later developed it personally with a smaller team for the event in 2019.
      </p>

      <h3>The Problem</h3>
      <p class="text">
        The Musikkonservatorium is a big building with multiple stories and many rooms. Therefore it can be hard to find the rooms with the 
        correct instruments. Before this project they had physical Guidancebooths where people could go and ask for the room numbers.
      </p>

      <h3>The Goal</h3>
      <p class="text">
        Our goal was to build a platform for the visitors so that they don't need physical guidance and can instead use their smartphone during 
        the event. We wanted the platform to be used before the event for gathering informations and picking instruments and during the event for guidance. 
        Lastly, it's also possible to sign up for the different music courses through our website.
      </p>

      <img class="processImage" src="../assets/Projekte/Infowerkstätte/4_prototyping.jpg">
    </div>

    <h2>The Process</h2>
    
    <div class="process">
      <h3>Research</h3>
      <p class="text">
        In the first phase of the project we conducted interviews with potential visitors and created personas and fitting scenarios.
      </p>
      
      <img class="processImage" src="../assets/Projekte/Infowerkstätte/1_personas.png">


      <h3>Card sorting</h3>
      <p class="text">
        In order to make the navigation through all the instruments understandable we conducted card sortings and tested paper prototypes with our co students.
      </p>

      <img class="processImage" src="../assets/Projekte/Infowerkstätte/3_cardsorting.jpg">

      <h3>Prototyping</h3>
      <p class="text">
        Each member of the group prepared a Styletile according to their liking from which we chose the best one. With that we started 
        with our prototype in Adobe Xd.
      </p>

      <img class="processImage" src="../assets/Projekte/Infowerkstätte/6_Prototyp3.png">

      <h3>Usability Testing</h3>
      <p class="text">
        We implemented multiple scenarios in the prototype which were then tested at the company Soultank AG in a real test environment with strangers. After that we went through some small changes and decided the final design before development.
      </p>

      <h3>Development</h3>
      <p class="text">
        After completing the module we continued the project privately and developed it fully. There my task was mainly programming the websites. <br>
         
      </p>

      <h2>Links</h2>
      <a target="_blank" href="https://info-werkstaetten.ch">www.info-werkstaetten.ch</a>

      <h3>Collaboration with</h3>
      <p class="collaboration">
        Chantal Steiger (Design), <br> Christoph Schmidt (Design), <br> Janine Richener (Design), <br> K. E. Vettiger (Design & Code), <br> Luka Kramer (Code)
      </p>
    </div>

  </div>

  <button class="backToBtn btmBtn projectBackBtn" v-on:click="scrollToTop">
      back to top
  </button>
</template>

<script>

export default {
  name: 'Info',
  mounted() { 
    window.scrollTo(0, 0)
  },
  methods: {
    scrollToTop: function (){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  h2{
    color: #70A9A1;
  }
</style>